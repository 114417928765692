<template>

  <div class="w-full mt-2">
    <router-link
      to="/forgot-password"
      class="link-plain">
      {{ $t('ForgotPasswordMsg') }}
    </router-link>
  </div>

</template>

<script>
/**
 * Link Forgot Password
 *
 * @module views/modules/components/ForgotPasswordLink
 * @author Jesus Niño <janmjesus@gmail.com>
 */
export default {
  name: 'ForgotPasswordLink',
  i18n: {
    messages: {
      en: {
        ForgotPasswordMsg: 'Forgot password?',
      },
    },
  },
};
</script>
