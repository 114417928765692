<template>
  <div class="h-screen w-full bg-white">
    <auth-logo-banner/>

    <div class="vx-row no-gutter w-full items-center justify-center pb-base bg-white">
      <div class="vx-col w-full sm:w-8/12 md:w-6/12 lg:w-4/12">
        <vx-card no-shadow card-border>
          <div
            slot="no-body"
            class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col w-full d-theme-dark-bg">
                <div class="px-4 xl:px-8 py-base login-tabs-container">
                  <vs-tabs
                    alignment="fixed"
                    :value="tabValue">
                    <vs-tab
                      :label="$t('$Auth.Login')"
                      @click="onTabChanged('Login')">
                      <login
                        class="mt-base"
                        @email-unverified-login-attempt="showEmailAccountVerifyModal"></login>
                    </vs-tab>

                    <vs-tab
                      :label="$t('$Auth.SignUpFree')"
                      @click="onTabChanged('SignUp')">
                      <register
                        class="mt-base"
                        @registered-user="showEmailAccountVerifyModal"></register>
                    </vs-tab>
                  </vs-tabs>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <vs-popup
      :title="$t('$Auth.$EmailAccountVerification.VerificationModalTitle', {
        email: this.emailToVerify
      })"
      :active.sync="activeModalVerify">
      <transition name="zoom-fade">
        <email-account-verification
          v-if="activeModalVerify"
          :email="emailToVerify"
          @close="activeModalVerify=false"
          @verified="activeModalVerify=false">
        </email-account-verification>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
// Components
import AuthLogoBanner from '@/views/pages/auth/components/AuthLogoBanner.vue';
import TheAuthLogin from '@/views/pages/auth/TheAuthLogin.vue';
import TheAuthRegister from '@/views/pages/auth/TheAuthRegister.vue';
import EmailAccountVerification from '@/views/pages/auth/components/EmailAccountVerification.vue';

/**
 * Page of auth, wrap the login and register components
 *
 * @module views/pages/auth/TheAuth
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} [operation='Login'] - operation to make ('Login', 'SignUp')
 * @vue-data {string} [localOperation='Login'] - reactive operation to make
 * @vue-computed {number} tabValue - Tab to show between login and register
 * @vue-event {void} onTabChanged - change localOperation when tab changed
 */
export default {
  name: 'TheAuth',
  components: {
    AuthLogoBanner,
    login: TheAuthLogin,
    register: TheAuthRegister,
    EmailAccountVerification,
  },
  props: {
    operation: {
      type: String,
      required: false,
      default: 'Login',
      validator(operation) {
        return ['Login', 'SignUp'].some((op) => op === operation);
      },
    },
  },
  data() {
    return {
      localOperation: this.operation,
      activeModalVerify: false,
      emailToVerify: '',
    };
  },
  computed: {
    tabValue() {
      return this.localOperation === 'Login' ? 0 : 1;
    },
  },
  created() {
    const uri = window.location.toString();
    if (uri.endsWith('.html')) {
      const cleanUri = uri.replace('.html', '');
      window.history.replaceState({}, document.title, cleanUri);
    }
  },
  methods: {
    onTabChanged(operation) {
      this.localOperation = operation;
    },
    showEmailAccountVerifyModal(email) {
      this.localOperation = 'Login';
      this.emailToVerify = email;
      this.activeModalVerify = true;
    },
  },
};
</script>
