<template>
  <div>
    <vs-alert
      color="success"
      icon-pack="feather"
      icon="icon-mail"
      :active="!invalidEnteredCode">
      <p>{{ $t('VerificationAlertMsg') }}
        <a
          href="#"
          class="ml-0"
          @click.prevent="resendVerificationEmail()">
          {{ $t('ResendNewVerificationEmail') | lowercase }}
        </a>.
      </p>
    </vs-alert>
    <vs-alert
      color="warning"
      icon-pack="feather"
      icon="icon-alert-triangle"
      :active="invalidEnteredCode">
      <p>{{ $t('EnteredCodeInvalidAlertMsg') }}
        <a
          href="#"
          class="ml-0"
          @click.prevent="resendVerificationEmail()">
          {{ $t('ResendNewVerificationEmail') | lowercase }}
        </a>.
      </p>
    </vs-alert>
    <form @submit.prevent>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            v-model="verificationCode"
            class="w-full required"
            :label="$t('VerificationCode')"
            :name="$t('VerificationCode')"
            v-validate="'required|digits:6'"
            data-vv-validate-on="blur|input"
            :danger="verifyCodeInputIsInvalid"
            :danger-text="invalidEnteredCodeErrorMsg"
            val-icon-danger="clear"
            @input="showInvalidEnteredCodeError = false"/>
        </div>
      </div>

      <base-form-footer-action-buttons
        @save="checkEnteredCode"
        @cancel="$emit('close')">
        <template v-slot:save>
          {{ $t('$General.Verify')}}
        </template>
      </base-form-footer-action-buttons>
    </form>
  </div>
</template>

<script>
// Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

// Services
import authService from '@/api/modules/auth.service';

/**
 * Component to verify an email account
 *
 * @module src/views/pages/auth/components/EmailAccountVerification
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} email - email account to verify
 * @vue-data {string} verificationCode - verification code entered
 * @vue-data {boolean} invalidEnteredCode - indicate if the entered code is invalid or no
 * @vue-data {boolean} showInvalidEnteredCodeError - indicate if show the error for invalid code
 * @vue-computed  {boolean} verifyCodeInputIsInvalid - indicate is code input field is invalid
 * @vue-computed  {string} invalidEnteredCodeErrorMsg - error to show for entered an invalid code
 * @vue-event  {void} resendVerificationEmail - resent and verification email
 * @vue-event  {void} checkEnteredCode - check entered verification code
 */
export default {
  name: 'EmailAccountVerification',
  i18n: {
    messages: {
      en: {
        VerificationAlertMsg: 'We have sent you a verification email with a code and a link. Please click the link in the email or enter the code below. If you have not received the email, you can',
        EnteredCodeInvalidAlertMsg: 'Email account could not be verified. Make sure the code is correct. If you have not received the email, you can',
        ResendNewVerificationEmail: 'Resend here',
        VerificationCode: 'Verification code',
        InvalidEnteredCodeErrorMsg: 'The Verification code entered is not valid',

        $ResendVerificationEmailNotify: {
          Title: 'Resend email verification',
          Msg: 'Verification email sent successfully',
        },
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verificationCode: '',
      invalidEnteredCode: false,
      showInvalidEnteredCodeError: false,
    };
  },
  computed: {
    verifyCodeInputIsInvalid() {
      return this.errors.has(this.$t('VerificationCode'))
        || this.showInvalidEnteredCodeError;
    },
    invalidEnteredCodeErrorMsg() {
      if (this.errors.has(this.$t('VerificationCode'))) {
        return this.errors.first(this.$t('VerificationCode'));
      }

      if (this.showInvalidEnteredCodeError) {
        return this.$t('InvalidEnteredCodeErrorMsg');
      }

      return '';
    },
  },
  watch: {
    invalidEnteredCode(val) {
      if (val) {
        this.showInvalidEnteredCodeError = true;
      }
    },
  },
  methods: {
    async resendVerificationEmail() {
      this.$vs.loading({ type: 'radius' });
      await authService.resendEmailValidateAccount(this.email);
      this.$vs.loading.close();

      this.verificationCode = '';
      this.invalidEnteredCode = false;
      this.showInvalidEnteredCodeError = false;

      this.$showSuccessActionNotification({
        title: this.$t('$ResendVerificationEmailNotify.Title'),
        text: this.$t('$ResendVerificationEmailNotify.Msg'),
      });

      await this.$validator.reset();
    },
    async checkEnteredCode() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });
      const resp = await authService.validateEmailAccountByCode({
        email: this.email,
        securityCode: this.verificationCode,
      });
      this.$vs.loading.close();
      const validationSuccess = resp.data;

      if (validationSuccess) {
        this.$showSuccessActionNotification({
          title: this.$t('$Auth.$EmailAccountVerification.VerificationNotificationTitle'),
          text: this.$t('$Auth.$EmailAccountVerification.VerificationNotificationOkMsg'),
        });

        this.verificationCode = '';
        this.$emit('verified');
      } else {
        this.invalidEnteredCode = true;
      }
    },
  },
};
</script>
