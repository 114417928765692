<template>
  <div class="w-full">
    <form @submit.prevent class="w-full">
      <vs-row>
        <vs-col
          class="px-2 sm:px-4"
          vs-w="12"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12">
          <vs-input
            v-model="model.name"
            class="w-full required"
            :label="$t('$General.Name')"
            :name="$t('$General.Name')"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-validate="'required|alpha_spaces|min:2|max:100'"
            data-vv-validate-on="input|blur"
            :danger="errors.has($t('$General.Name'))"
            :danger-text="errors.first($t('$General.Name'))"
            val-icon-danger="clear"/>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5">
        <vs-col
          vs-w="12"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
          class="px-2 sm:px-4">
          <vs-input
            v-model="model.email"
            class="w-full required"
            type="email"
            :name="$tc('$General.Email')"
            :label="$tc('$General.Email')"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-validate="'required|email|min:5|max:100'"
            data-vv-validate-on="input|blur"
            :danger="emailHasErrors"
            :danger-text="emailErrorMsg"
            val-icon-danger="clear"
            @input="duplicatedEmailError = false"/>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5">
        <vs-col
          vs-w="12"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
          class="px-2 sm:px-4">
          <vs-input
            v-model="model.password"
            type="password"
            class="w-full required"
            :name="$t('$General.Password')"
            :label="$t('$General.Password')"
            icon-no-border
            icon="icon icon-lock"
            icon-pack="feather"
            v-validate="'required|password'"
            data-vv-validate-on="input|blur"
            :danger="errors.has($t('$General.Password'))"
            :danger-text="errors.first($t('$General.Password'))"
            val-icon-danger="clear"/>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5">
        <vs-col
          vs-w="12"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
          class="px-2 sm:px-4">
          <review-terms-and-conditions
            ref="termsAndConditions"
            :confirm-button="false"
            :hide-validation-msg="activeModalTermsConditions"
            @termsAndConditions="(val) => this.model.acceptTermsAndConditions = val"
            @newsAndUpdates="(val) => this.model.acceptReceiveNewsAndUpdates = val"/>
        </vs-col>
      </vs-row>

      <vs-row class="mt-5">
        <vs-col
          vs-w="12"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
          class="flex items-center px-2 sm:px-4">
          <vs-button
            class="w-full"
            @click.prevent="register()">{{ $t('$Auth.SignUpWithEmail') }}
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-divider class="my-6">{{ $t('$General.Or') | uppercase }}</vs-divider>

      <sign-in-google-button @click="activeModalTermsConditions=true">
        {{ $t('$Auth.SignUpWithGoogle') }}
      </sign-in-google-button>
    </form>

    <vs-popup
      :title="$t('$Auth.ReviewTermsConditionsToRegister')"
      :active.sync="activeModalTermsConditions">
      <transition name="zoom-fade">
        <review-terms-and-conditions
          v-if="activeModalTermsConditions">
          {{ $t('$Auth.SignUpWithGoogle') }}
        </review-terms-and-conditions>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

// Components
import SignInGoogleButton from '@/views/pages/auth/components/SignInGoogleButton.vue';
import ReviewTermsAndConditions from '@/views/pages/auth/components/ReviewTermsAndConditions.vue';

/**
 * Component to register on the app
 *
 * @module views/pages/auth/TheAuthLogin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} model - register model
 * @vue-data {boolean} activeModalTermsConditions -
 * indicate if show or no the terms & conditions modal
 * @vue-event {void} register - register and notify the user
 */
export default {
  name: 'TheAuthRegister',
  components: {
    SignInGoogleButton,
    ReviewTermsAndConditions,
  },
  i18n: {
    messages: {
      en: {
        DuplicatedEmailErrorMsg: 'That email is taken. Try another',
      },
    },
  },
  data() {
    return {
      model: {
        name: '',
        email: '',
        password: '',
        acceptTermsAndConditions: false,
        acceptReceiveNewsAndUpdates: false,
      },
      activeModalTermsConditions: false,
      duplicatedEmailError: false,
    };
  },
  computed: {
    emailHasErrors() {
      return this.errors.has(this.$tc('$General.Email')) || this.duplicatedEmailError;
    },
    emailErrorMsg() {
      return this.errors.first(this.$tc('$General.Email')) || this.$t('DuplicatedEmailErrorMsg');
    },
  },
  methods: {
    ...mapActions({
      signUp: 'auth/signUp',
    }),
    async register() {
      const formIsValid = await this.$validator.validate();
      const termsAndConditionsValid = await this.$refs.termsAndConditions.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      if (!termsAndConditionsValid) {
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        await this.signUp(this.model);

        this.$showSuccessActionNotification({
          title: this.$t('$Auth.SignUpAttempt'),
          text: this.$t('$Auth.SuccessfullySignUpMsg'),
        });

        this.$emit('registered-user', this.model.email);
      } catch (e) {
        if (e.response && e.response.data
          && e.response.data.duplicatedEmail) {
          this.duplicatedEmailError = true;
        } else {
          throw e;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
